import React from "react";
import {Form} from "react-bootstrap";

const CustomCellEditor = (props) =>{
    const {colDef, setColumnDefs, stopEditing} = props;

    let newColDefs = [...props.gridColumnApi.columnModel.columnDefs]
    const handleSumChange = (e) => {
        const checked = e.target.checked;
        setColumnDefs(newColDefs.map((col) => {
            return col.field === colDef.field ? {...col, isSumEnabled: checked}: col
        }))
        stopEditing()
    }

    const handleAvgChange = (e) => {
        const checked = e.target.checked;
        setColumnDefs(newColDefs.map((col) => {
            return col.field === colDef.field ? {...col, isAvgEnabled: checked}: col
        }))
        stopEditing()
    }

    return(
        <container>
            <Form.Group controlId="formGridState" className={"px-3"} style={{fontSize: "12px"}}>
                <Form.Check type="checkbox"
                            checked={colDef.isSumEnabled}
                            onChange={handleSumChange}
                            label={"SUM"}
                            className={'pl-3'}/>
                <Form.Check type="checkbox"
                            checked={colDef.isAvgEnabled}
                            onChange={handleAvgChange}
                            label={"AVG"}
                            className={'pl-3'}/>
            </Form.Group>
        </container>
    )

}
export default CustomCellEditor