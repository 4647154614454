import {Col, Row} from "react-bootstrap";
import RightArrowButton from "./RightArrowButton";
import React, {useContext, useEffect, useState} from "react";
import DropDownMenu from "../DropDownMenu";
import ColumnsModal from "../ColumnsModal";

export default function ColumnsButton(props){

    return(
        <>
            <Col className={"text-nowrap d-none d-md-block p-0 m-0" + (props.showDropdown ? " active-dropdown-button" : "" )}>
                    <DropDownMenu title={props.title} showDropdown={props.showDropdown} setShowDropdown={props.setShowDropdown}>
                        <ColumnsModal
                            columnDefs={props.columnDefs}
                            setColumnDefs={props.setColumnDefs}
                            gridApi={props.gridApi}
                            onColumnFilterClick={props.onColumnFilterClick}
                            gridColumnApi={props.gridColumnApi}
                            columnState={props.columnState}
                        />
                    </DropDownMenu>

            </Col>
            <Col className="text-nowrap d-none d-sm-block d-md-none p-0 m-0">
                <a href="#" onClick={props.onColumnsButtonClick}>Columns</a>
            </Col>
            <Col className="text-nowrap d-block d-sm-none p-0 m-0">
                <Row onClick={props.onColumnsButtonClick} >
                    <Col xs={6} className={'py-2 pl-4'} style={{alignSelf: 'center'}}><p style={{float:'left'}}>Columns</p></Col>
                    <Col xs={6} className={'py-2 pr-2'} style={{alignSelf: 'center'}}> <RightArrowButton/></Col>
                    <Col xs={12}  className={'py-2 pr-0 pl-3'}> <hr/></Col>
                </Row>
            </Col>
        </>
    )
}