import {createContext, useContext, useState} from "react";
import {
    getCurrentBusDateOrCurrentDate
} from "utils/FormatUtils";
import UserRoleService, {
    WIRES_MENU_CLIENT_APPROVER_PERMISSION_KEY,
    WIRES_MENU_SUBMITTER_PERMISSION_KEY
} from "../../../service/UserRoleService";
import AuthenticationService from "../../../service/AuthenticationService";
import {getSessionAttribute} from "../../../utils/SessionUtils";
import {SELECTED_ACCOUNT} from "../../../utils/Constants";
import {ToastContext} from "../../../context/Toast";

export const WIRE_PANEL_HOME_PAGE = "wire-home";
export const WIRE_PANEL_VIEW_WIRE_PAGE = "view-wire";

export const Context = createContext({});

const endDate =  getCurrentBusDateOrCurrentDate();

const initWireFilterState = {
    managerId:'',
    accountId:'',
    legalEntity:'',
    clientCode:'',
    status:'',
    startDate:endDate,
    endDate
}

const initWireResultsDisplay = {
    isSavedWire:false,
    savedWireListId:'',
    savedName :'',
    wireSearch:{},
    resData:[]
}

const initWirePanelPageState = {
    currentPage : WIRE_PANEL_HOME_PAGE,
}

const initWireState = {
    userCode:'',
    managerId:'',
    managerName:'',
    accountId:'',
    accountName:'',
    legalEntity:'',
    clientCode:'',
    status:'',
    moneyOut:0.0,
    moneyIn:0.0,
    incomingCcy:'USD',
    outgoingCcy:'USD',
    effectiveDate:'',
    thirdPartyCode:'N',
    instructions:'',
    reason:'',
    version:'',
    indCancel:'',
    indTemplate: 'N',
    templateName :'',
    comments:'',
    wireId:'',
    startDate:'',
    endDate:'',
    resData:[],
    currentPage : WIRE_PANEL_HOME_PAGE,
}


export const Provider = props => {

    const {children} = props;
    const [wirePanelFilterState, setWirePanelFilterState] = useState(JSON.parse(JSON.stringify(initWireFilterState)));
    const [wireResultsDisplayState, setWireResultsDisplayState] = useState(JSON.parse(JSON.stringify(initWireResultsDisplay)));
    const [wireNewState, setWireNewState] = useState(initWireState);
    const [wirePanelPageState, setWirePanelPageState] = useState(JSON.parse(JSON.stringify(initWirePanelPageState)));
    const [handleApproveFunction, setHandleApproveFunction] = useState(null);
    const [handleRejectFunction, setHandleRejectFunction] = useState(null);
    const [showGridToolbar, setShowGridToolbar] = useState(true);
    const [wireTemplatesList, setWireTemplatesList] = useState([]);
    const [wireCurrenciesList, setWireCurrenciesList] = useState([]);
    const [hasWiresSubmitterPermission, setWiresSubmitterPermission] = useState(UserRoleService.hasPermission(WIRES_MENU_SUBMITTER_PERMISSION_KEY));
    const [hasWiresApproverPermission, setWiresApproverPermission] = useState(UserRoleService.hasPermission(WIRES_MENU_CLIENT_APPROVER_PERMISSION_KEY));
    const [disableRequestWires, setDisableRequestWires] = useState(false);



    const handleApprove = (onSuccess, onError) => {
        handleApproveFunction.handleApprove(
            handleApproveFunction.gridApi, onSuccess, onError);
    }

    const handleReject = (onSuccess, onError) => {
        handleRejectFunction.handleReject(
            handleRejectFunction.gridApi, onSuccess, onError);
    }

    const clearWirePanelDisplayState = () => {
        const clearWirePanelFilterState = JSON.parse(JSON.stringify(initWireFilterState));
        setWirePanelFilterState(clearWirePanelFilterState);

        const clearWiresResultsDisplayState = JSON.parse(JSON.stringify(initWireResultsDisplay));
        setWireResultsDisplayState(clearWiresResultsDisplayState);

        const clearWirePanelPageState = JSON.parse(JSON.stringify(initWirePanelPageState));
        setWirePanelPageState(clearWirePanelPageState);

    }

    const clearWireNewState = () =>{
        const clearWireNewState = JSON.parse(JSON.stringify(initWireState));
        setWireNewState(clearWireNewState);
        setWireTemplatesList([]);
        setWireCurrenciesList([]);
    }

    const displayStatusMapping = {
        "Pend":  "Pend",
        "Appr": "Approved",
        "Cmplt": "Completed",
        "Reject": "Reject",
        "ALL": "ALL"
    }
    const getDisplayStatusFromValue = (value) => {
        if (value){
            return displayStatusMapping[value];
        } else {
            return "";
        }
    }

    const displayWirePanelPage = (wirePanelPage) => {
        let wirePanelPageStateToShow =JSON.parse(JSON.stringify(wirePanelPageState));
        wirePanelPageStateToShow.currentPage = wirePanelPage;
        setWirePanelPageState(wirePanelPageStateToShow);
    }

    const isWireSubmissionAllowedForAccount = () => {

        let entityAccountsFromSession = AuthenticationService.getEntityAccounts();
        let accountSelectedFromSession = getSessionAttribute(SELECTED_ACCOUNT)?.accountCode

        for (const manager of entityAccountsFromSession) {
            const accountSelected = manager.accounts.find(accountDetails => accountDetails.accountCode === accountSelectedFromSession )
            if(accountSelected){
                let disableRequestWiresValue = !Boolean(accountSelected?.wireSubmitter)
                setDisableRequestWires((prev) => {
                    return disableRequestWiresValue;
                })
                return ;
            }
        }
        setDisableRequestWires(false)
    }

    const wiresPanelContext = {
        wirePanelFilterState: wirePanelFilterState,
        setWirePanelFilterState: setWirePanelFilterState,
        wireResultsDisplayState: wireResultsDisplayState,
        setWireResultsDisplayState: setWireResultsDisplayState,
        wireNewState: wireNewState,
        setWireNewState: setWireNewState,
        wirePanelPageState: wirePanelPageState,
        setWirePanelPageState:setWirePanelPageState,
        wireTemplatesList: wireTemplatesList,
        setWireTemplatesList: setWireTemplatesList,
        wireCurrenciesList: wireCurrenciesList,
        setWireCurrenciesList: setWireCurrenciesList,
        showGridToolbar,
        setShowGridToolbar,
        displayWirePanelPage: displayWirePanelPage,
        clearWirePanelDisplayState: clearWirePanelDisplayState,
        clearWireNewState : clearWireNewState,
        setHandleApproveFunction:setHandleApproveFunction,
        handleApprove:handleApprove,
        setHandleRejectFunction:setHandleRejectFunction,
        handleReject:handleReject,
        getDisplayStatusFromValue: getDisplayStatusFromValue,
        hasWiresSubmitterPermission,
        hasWiresApproverPermission,
        disableRequestWires,
        isWireSubmissionAllowedForAccount
    }

    return <Context.Provider value={wiresPanelContext}>{children}</Context.Provider>

};

export const {Consumer} = Context;
