import axios from 'axios';
import {
    AUTHENTICATE_MFA_URL,
    AUTHENTICATE_URL,
    COMMON_BUS_DATES,
    RESET_EXPIRED_URL,
    MFA_LOGOUT_URL,
    MFA_REDIRECT_URL,
    MFA_UPDATE_PASSWORD_URL
} from './UrlConstant';
import {COMMON_SESSION_ATTRIBUTE_BUSDATES} from "../pages/login/context/LoginContext";
import {
    SELECTED_ACCOUNT,
    SELECTED_ENTITY,
    ACCOUNTS_OPTIONS,
    SELECTED_PAGINATION_SIZE,
    TRADE_BREAK_STATE,
    LEGAL_ENTITY
} from "../utils/Constants";

export const PROFILE_SESSION_ATTRIBUTE_NAME = 'UserProfile'
export const JWT_SESSION_ATTRIBUTE_NAME = 'jwt'
export const JWTOPM_SESSION_ATTRIBUTE_NAME = 'jwtOpm'
export const LAST_REQUEST_TIME = 'LAST_REQUEST_TIME'
export const USER_ENTITY_ACCOUNTS = 'USER_ENTITY_ACCOUNTS'

class AuthenticationService {

    authenticateUser(username, password) {
        return axios.post(AUTHENTICATE_URL, {
            username:username,
            password:password
        })
    }

    authenticateMFAUser(code) {
        return axios.post(AUTHENTICATE_MFA_URL, {
            code:code
        })
    }

    MFALoginOption() {
        window.location.href=MFA_REDIRECT_URL;
    }

    redirectToHome() {
        window.location.href="/";
    }

    resetExpiredPassword(username, password, newPassword, confirmNewPassword) {
        return axios.post(RESET_EXPIRED_URL, {
            username:username,
            password:password,
            newPassword:newPassword,
            confirmNewPassword:confirmNewPassword
        })
    }

    setSessionVariables(response){
        sessionStorage.setItem(PROFILE_SESSION_ATTRIBUTE_NAME, JSON.stringify(response.data.user))
        sessionStorage.setItem(USER_ENTITY_ACCOUNTS, JSON.stringify(response.data.entityAccounts))
        sessionStorage.setItem(JWT_SESSION_ATTRIBUTE_NAME, response.headers.authorization)
        sessionStorage.setItem(JWTOPM_SESSION_ATTRIBUTE_NAME, response.headers.cookie)
        this.updateLastRequestTime()
    }

    updateSessionToken(response) {
        sessionStorage.setItem(JWT_SESSION_ATTRIBUTE_NAME, response.headers.authorization);
        sessionStorage.setItem(JWTOPM_SESSION_ATTRIBUTE_NAME, response.headers.cookie)
        this.updateLastRequestTime();
    }
    getMfaUpdatePasswordUrl(){
        return MFA_UPDATE_PASSWORD_URL
    }
    logout() {
        sessionStorage.removeItem(PROFILE_SESSION_ATTRIBUTE_NAME);
        sessionStorage.removeItem(JWT_SESSION_ATTRIBUTE_NAME);
        sessionStorage.removeItem(JWTOPM_SESSION_ATTRIBUTE_NAME);
        sessionStorage.removeItem(LAST_REQUEST_TIME);
        sessionStorage.removeItem(USER_ENTITY_ACCOUNTS);
        sessionStorage.removeItem(COMMON_SESSION_ATTRIBUTE_BUSDATES);
        sessionStorage.removeItem(SELECTED_ENTITY);
        sessionStorage.removeItem(SELECTED_ACCOUNT);
        sessionStorage.removeItem(SELECTED_PAGINATION_SIZE);
        sessionStorage.removeItem(ACCOUNTS_OPTIONS);
        sessionStorage.removeItem(TRADE_BREAK_STATE);
        sessionStorage.removeItem(LEGAL_ENTITY);
        window.location.href=MFA_LOGOUT_URL
    }

    isUserAuthenticated() {
        let user = this.getAuthenticatedUserName();
        let token = this.getUserAuthenticationToken();
        return (user && token);
    }

    getAuthenticatedUserName() {
        let profile = this.getProfile();
        return (profile && profile.username ? profile.username : '');
    }

    getProfile() {
        const profile = sessionStorage.getItem(PROFILE_SESSION_ATTRIBUTE_NAME);
        return (profile ? JSON.parse(profile) : {});
    }

    getUserAuthenticationToken() {
        return sessionStorage.getItem(JWT_SESSION_ATTRIBUTE_NAME)
    }

    getUserAuthenticationOpmToken() {
        return sessionStorage.getItem(JWTOPM_SESSION_ATTRIBUTE_NAME)
    }

    getLastRequestTime() {
        return sessionStorage.getItem(LAST_REQUEST_TIME);
    }

    updateLastRequestTime() {
        sessionStorage.setItem(LAST_REQUEST_TIME, new Date().getTime().toString())
    }

    getEntityAccounts() {
        const entityAccounts = sessionStorage.getItem(USER_ENTITY_ACCOUNTS)
        return (entityAccounts ? JSON.parse(entityAccounts) : []);
    }

    getBusinessDates(){
        return axios.get(COMMON_BUS_DATES);
    }

    isInternalUser(){
        let permissions = this.getProfile().permissions
        let intExtFlag = null
        if (permissions)
            intExtFlag = permissions.substring(0,1)
        return intExtFlag === 'I' ? true : false
    }

}

export default new AuthenticationService()