import React, { useContext, useEffect, useRef, useState, useCallback} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import ActionsRenderer from "components/ActionsRenderer";
import AgGridToolbar from "components/AgGridToolbar/AgGridToolbar";
import PageHeader from "components/PageHeader";
import useWindowDimensions from "hooks/Dimensions";
import {QueriesPanelContext} from "./context";
import QueriesParamsBadge from "./QueriesParamsBadge";
import {ActionPanelContext} from "context/action";
import {QUERIES_QUERY_SAVE} from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {dateToString} from "utils/FormatUtils";
import {UtilsContext} from "context/utils";
import {ToastContext} from "context/Toast";
import * as URL from "service/UrlConstant";
import {QUERY_PANEL_VIEW_QUERY_PAGE} from "./context/QueriesPanelContext";
import {ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE} from "utils/Constants";
import {getColDef} from "./QueryColumnDefs";
import AuthenticationService from "../../service/AuthenticationService";
import {createExcelExportParams, getFilteredColumns} from "../../utils/ExcelExportUtils";
 import {getDisplayedNumberOfGridRows, updateGridColumDefs} from "../../utils/gridColumnUtils";
 import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";
 import "./queryFooter.css"
import CustomCellEditor from "./CustomCellEditor";

export default function QueriesResultsPanel() {

    const {queryResultsDisplayState, setQueryResultsDisplayState, clearQueryPanelDisplayState, queryPanelPageState, showGridToolbar, isAggEnabled, setIsAggEnabled} = useContext(QueriesPanelContext)
    const {
        setHandleSaveFunction,
        setHandleRenameFunction,
        setHandleDeleteFunction,
        setHandleExportFunction,
        setSavedSearchName
    } = useContext(ActionPanelContext);
    const {hiddenExportGridApi,paginationPageSize, setPaginationPageSize, showButton, setShowButton} = useContext(UtilsContext);
    const {makePostRequest, makePatchRequestWithParams, makeDeleteRequest} = useContext(SecurityContext)
    const {clearEntityAccounts} = useContext(EntityAccountsContext);
    const {success, error} = useContext(ToastContext);

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const {isMobile} = useWindowDimensions()
    const [usePortalColumns,setUsePortalColumns] = useState(false);
    const queriesResultsGrid = useRef(null);

    const [bottomRow, setBottomRow] = useState([])
    const [sum, setSum] = useState([])
    const [avg, setAvg] = useState([])
    const [cellEditingStoppedFlag, setCellEditingStoppedFlag] = useState(false)

    const columnTypes ={
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: true,
            filter: true,
            enableCellChangeFlash: false,
            suppressMenu:true,
            flex:1,
            suppressMovable:true,
        }
    }

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:isMobile? true : false,
    };

    const saveQuerySearch = (name, gridApi, onSuccess, onError) => {
        const params = {
            savedQueryName: name,
            clientCode: queryResultsDisplayState.querySearch.accountCode,
            managerId: queryResultsDisplayState.querySearch.managerId,
            subManagerId: queryResultsDisplayState.querySearch.subManagerId,
            accountId:queryResultsDisplayState.querySearch.accountId,
            queryId: queryResultsDisplayState.querySearch.queryId,
            startDate: (queryResultsDisplayState.querySearch.startDate ? dateToString(queryResultsDisplayState.querySearch.startDate) : ''),
            endDate: (queryResultsDisplayState.querySearch.endDate ? dateToString(queryResultsDisplayState.querySearch.endDate) : '')
        };

        const onSuccessResponse = (response) => {
            if(onSuccess) onSuccess();
            const queryResultsDisplay = JSON.parse(JSON.stringify(queryResultsDisplayState));
            queryResultsDisplay.isSavedQuery = true;
            queryResultsDisplay.savedName = response.data.savedQueryName;
            queryResultsDisplay.savedQueryId = response.data.savedQueryId;
            setQueryResultsDisplayState(queryResultsDisplay);
            setSavedSearchName(name);
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        makePostRequest(params, QUERIES_QUERY_SAVE, onSuccessResponse, onErrorResponse);
    }

    const updateQuerySearch = (updatedSavedName, gridApi, onSuccess, onError) => {

        const params = {
            "savedNameUpdated":updatedSavedName,
        }

        const onSuccessResponse = (response) => {
            if (onSuccess) onSuccess();
            const queryResultsDisplay = JSON.parse(JSON.stringify(queryResultsDisplayState));
            queryResultsDisplay.savedName = updatedSavedName;
            setQueryResultsDisplayState(queryResultsDisplay);
            setSavedSearchName(updatedSavedName);
            success("Successfully updated saved query search name");
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        makePatchRequestWithParams(
            URL.QUERIES_QUERY_SAVE + "/" + queryResultsDisplayState.savedQueryId , params, onSuccessResponse, onErrorResponse);
    };

    const deleteQuerySearch = (gridApi, onSuccess, onError) => {

        const onSuccessResponse = (response) => {
            if (onSuccess) onSuccess();
            clearEntityAccounts();
            clearQueryPanelDisplayState();
            success("Query search successfully deleted");
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if (onError) onError(err);
            error("Error occurred with deleting saved query search");
        }
        makeDeleteRequest(
            URL.QUERIES_QUERY_SAVE + "/" + queryResultsDisplayState.savedQueryId, onSuccessResponse, onErrorResponse);
    };

    const exportQueryList = (type, rowData, gridApi, onSuccess, onError) => {
        const legacyHeader = getLegacyHeader(queryResultsDisplayState.querySearch.managerId);

        //Set Column definition
        hiddenExportGridApi.current.api.setColumnDefs(getColDef(!legacyHeader, queryResultsDisplayState.querySearch.queryId));
        //Set Data
        hiddenExportGridApi.current.api.setRowData(rowData || queryResultsDisplayState.resData);
        let filteredColumnNames = getFilteredColumns(queriesResultsGrid);
        //Export as csv/excel
        type==='EXCEL' ?
            hiddenExportGridApi.current.api.exportDataAsExcel(createExcelExportParams('Queries', filteredColumnNames)) :
                hiddenExportGridApi.current.api.exportDataAsCsv({ columnKeys: filteredColumnNames})
    }

    const getLegacyHeader = function(entity){
        if (!entity) return false;
        const entityOptions = JSON.parse(JSON.stringify(AuthenticationService.getEntityAccounts()));
        const obj = entityOptions.filter(function(item) { return item.managerId.toString() === entity.toString()});
        if(obj && obj.length >0)
            return obj[0].legacyHeader;
        else
            return false;
    }

    useEffect(() => {
        setUsePortalColumns(false);
        let columnDefinition;
        if (QUERY_PANEL_VIEW_QUERY_PAGE === queryPanelPageState.currentPage) {
            const legacyHeader = getLegacyHeader(queryResultsDisplayState.querySearch.managerId);
            setUsePortalColumns(legacyHeader);
            // handle the special case of 'Margin Requirement'
            if (queryResultsDisplayState.resData != null && queryResultsDisplayState.resData.length > 0
                && queryResultsDisplayState.querySearch.queryId === 'Margin Requirements'
                && 'Comment_1' in queryResultsDisplayState.resData[0]){
                    columnDefinition = getColDef(!legacyHeader,queryResultsDisplayState.querySearch.queryId,1)
                    setColumnDefs(columnDefinition);
            }
            // Normal Flow
            else{
                columnDefinition = getColDef(!legacyHeader,queryResultsDisplayState.querySearch.queryId)
                setColumnDefs(columnDefinition);
            }
            setRowData(queryResultsDisplayState.resData);
            setHandleSaveFunction({handleSave: saveQuerySearch, gridApi: null});
            setHandleRenameFunction({handleRename: updateQuerySearch, gridApi: null});
            setHandleDeleteFunction({handleDelete: deleteQuerySearch, gridApi: null});
            setHandleExportFunction({handleExport:exportQueryList, gridApi: gridApi});
            let {sumRows, avgRows} = calculateAggregations(queryResultsDisplayState.resData, columnDefinition)
        }
    },[queryPanelPageState,queryResultsDisplayState])

    const calculateAggregations=(data, columnDefs)=>{
        const sumRows = {}
        const avgRows = {}
            columnDefs?.forEach((col)=>{
                if(col?.isSumEnabled !== undefined && col?.isAvgEnabled !== undefined){
                    const values = data.map((row)=>parseFloat(row[col.field]) || 0)
                    const sum = values.reduce((acc,val)=> acc+val,0)
                    const avg = values.length > 0 ? sum/values.length : 0
                    sumRows[col.field] = sum;
                    avgRows[col.field] = avg;
                }
                else{
                    sumRows[col.field] = null;
                    avgRows[col.field] = null;
                }
            })
        setSum(sumRows)
        setAvg(avgRows)
        return {sumRows, avgRows};
    }

    const onDisplayedColumnsChanged = ()=> {
        if(gridColumnApi){
            const updatedColumnDefs = updateGridColumDefs(gridColumnApi, columnDefs)
            setColumnDefs(updatedColumnDefs)
        }
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }

    function pageTitle(){
        return (<>Query</>)
    }

    const onDataRendered = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.columnApi.autoSizeAllColumns();
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    const onFilterChange = (params) =>{
        let arrayOfEveryNode = []

        gridApi.forEachNodeAfterFilter((rowNode) => {
            arrayOfEveryNode.push(rowNode.data)})

        let {sumRows, avgRows} = calculateAggregations(arrayOfEveryNode, columnDefs)
        setSum(sumRows)
        setAvg(avgRows)
    }

    const tdFilterLogo = function (props){
        let aggType
        let displayedValue
        if(props?.colDef.isAvgEnabled || props?.colDef.isSumEnabled){
            //Sometimes the displayed value has no formatting and so the valueFormatted value will be undefined, this is to catch that case
            displayedValue = (props?.valueFormatted && props?.value !== null) ? props?.valueFormatted: props?.value
            if(props?.colDef.isSumEnabled === true && props?.colDef.isAvgEnabled === false){
                aggType = 'SUM:'
            }
            else if(props?.colDef.isSumEnabled === false && props?.colDef.isAvgEnabled === true){
                aggType = 'AVG:'
            }
            else{
                if(props?.node.id === 'b-0'){
                    aggType = 'SUM'
                }
                if(props?.node.id === 'b-1'){
                    aggType = 'AVG'
                }
            }

            return (<Container>
                    <Row style={{display: "flex", justifyContent: "space-between",alignItems: "flex-start"}}>
                        <Col style={{textAlign: "left", lineHeight: "18px"}} className="p-0">
                            <span className="td-icon-colour-primary td-icon td-icon-filter" style={{color: '#181d1f', fontSize: "12px", lineHeight: 1}}/>
                            <span style={{color: '#181d1f',fontWeight: 'normal', fontSize: "12px"}}>{aggType}</span>
                        </Col>
                        <div className="w-100"></div>
                        <Col style={{textAlign: "right", lineHeight: "18px"}} className="p-0">
                            <span>{displayedValue}</span>
                        </Col>
                    </Row>
                </Container>
            );
        }
        else if (props?.colDef.isAvgEnabled === false && props?.colDef.isSumEnabled === false){
            displayedValue = null

            return (
                <Col style={{textAlign: "left", lineHeight: "40px"}} className="p-0">
                    <span className="td-icon-colour-primary td-icon td-icon-filter" style={{color: '#181d1f', fontSize: "12px", lineHeight: 1}}/>
                </Col>
            );
        }
        else{
            return nullBottomRow();
        }

    };

    const nullBottomRow = function (props){
        return <span></span>
    };

    useEffect(() => {
        let localIsAggEnabled= false
        columnDefs.map((columnDef)=>{
            if((columnDef?.isAvgEnabled || columnDef?.isSumEnabled) && gridColumnApi && rowData && rowData?.length > 0){
                localIsAggEnabled = true
                setIsAggEnabled(true)
            }
        })

        if(localIsAggEnabled){
            const updatedColumnDefs = columnDefs.map((columnDef)=>{

                return {...columnDef, cellRendererSelector: p=>{
                        if((p?.value !== null ||
                            //Sometimes there is no value in a cell, but there should be a formatted value (i.e. nulls are often formatted to 0). This is to still display a value
                            (p?.valueFormatted !== undefined &&
                            p?.valueFormatted !== null)) &&
                            p?.node && p?.node?.rowPinned === "bottom" &&
                            //This is checking for the specific case where there are 2 bottom rows. If there are 2 bottom rows then both aggregations MUST be enabled
                            (p.node.id === "b-1"? p.colDef.isAvgEnabled === true && p.colDef.isSumEnabled === true: true)){
                            return {component: 'tdFilterLogo', params: p.node}
                        }
                        else if(p?.value === null &&
                                p.node.id === "b-1" &&
                                !(p.colDef.isAvgEnabled === true && p.colDef.isSumEnabled === true)){
                            return {component: 'nullBottomRow', params: p}
                        }
                    },
                    editable: p =>{
                        return p?.node && p?.node?.rowPinned === "bottom" && ((p?.colDef.isAvgEnabled || p?.colDef.isSumEnabled) || (p?.colDef.isAvgEnabled === false || p?.colDef.isSumEnabled === false))
                    },
                    cellEditorPopup: true,
                    cellEditor: 'CustomCellEditor',
                    cellEditorSelector: (row) => {
                        if(row?.node && row?.node?.rowPinned === "bottom"){
                            return {
                                component: 'CustomCellEditor',
                                params: {columnDefs, setColumnDefs, gridApi, gridColumnApi},
                                popup: true,
                            };
                        }

                        else return undefined;
                    },
                }
            })
            setColumnDefs(updatedColumnDefs);
        }
        else{
            gridApi?.setPinnedBottomRowData([])
        }
    },[gridColumnApi, rowData])

    const onCellEditingStopped = (event) => {
        setCellEditingStoppedFlag(!cellEditingStoppedFlag)
    }

    useEffect(() => {
        if(isAggEnabled){
            let topRows = {}
            let bottomRows={}

            columnDefs?.forEach((col)=>{
                if(col?.isSumEnabled === true && col?.isAvgEnabled === false){
                    topRows[col.field] = sum[col.field];
                    bottomRows[col.field] = null;
                }
                else if(col?.isSumEnabled === false && col?.isAvgEnabled === true){
                    topRows[col.field] = avg[col.field];
                    bottomRows[col.field] = null;
                }
                else if(col?.isSumEnabled === true && col?.isAvgEnabled === true){
                    topRows[col.field] = sum[col.field];
                    bottomRows[col.field] = avg[col.field];
                }
                else if(col?.isSumEnabled === false && col?.isAvgEnabled === false){
                    topRows[col.field] = null;
                    bottomRows[col.field] = null;
                }
                else{
                    topRows[col.field] = null;
                    bottomRows[col.field] = null;
                }
            })

            const isTopRowNull = Object.values(topRows).every(value => value === null)
            const isBottomRowNull = Object.values(bottomRows).every(value => value === null)

            if(isTopRowNull) return

            if(isBottomRowNull){
                setBottomRow([topRows])
                gridApi?.setPinnedBottomRowData([topRows])
            }
            else{
                setBottomRow([topRows, bottomRows])
                gridApi?.setPinnedBottomRowData([topRows, bottomRows])
            }
        }
    },[sum, avg, cellEditingStoppedFlag, isAggEnabled])

    const rowClassRules = {
        'aggregation-rows': (params) => {return params?.node?.rowPinned},
    }

    const getRowHeight =(params) => {
        let rowHeight
        params.node.rowPinned === "bottom" ? rowHeight = 38: rowHeight = rowHeight
        return rowHeight
    }

    return(<>

        <Container fluid>
            <PageHeader text={pageTitle()}>
                <QueriesParamsBadge />
            </PageHeader>

            {showGridToolbar && <AgGridToolbar queryScreen={true}
                           title={queryResultsDisplayState.savedName}
                           isSaved={queryResultsDisplayState.isSavedQuery}
                           gridApi={gridApi}
                           gridColumnApi={gridColumnApi}
                           columnDefs={columnDefs} setColumnDefs={setColumnDefs}
                           paginationPageSize={paginationPageSize}
                           setPaginationPageSize={setPaginationPageSize}
                           showButton={showButton} setShowButton={setShowButton}/>}

            <div className="ag-theme-alpine">
                <AgGridReact
                    ref={queriesResultsGrid}
                    domLayout={'autoHeight'}
                    frameworkComponents={{
                        actionsRenderer: ActionsRenderer,
                        tdFilterLogo: tdFilterLogo,
                        CustomCellEditor: CustomCellEditor,
                        nullBottomRow: nullBottomRow
                    }}
                    paginationPageSize={paginationPageSize}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onDataRendered}
                    onRowDataChanged={onDataRendered}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    columnTypes={columnTypes}
                    rowData={rowData}
                    animateRows={true}
                    onPaginationChanged={onPaginationChange}
                    pinnedBottomRowData={bottomRow}
                    onFilterChanged={onFilterChange}
                    rowClassRules={rowClassRules}
                    onCellEditingStopped={onCellEditingStopped}
                    getRowHeight={getRowHeight}
                >
                </AgGridReact>
            </div>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}
    </>);
}