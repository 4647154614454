    import {createContext, useState} from "react";
import {
    nonEmptyNumberFormatter,
    numberFormat,
    numberFormat3FractionalDigitsStr, numberFormat4FractionalDigits,
    numberFormatIntegerStr
} from "utils/NumberUtils";
export const Context = createContext({});

export const Provider = props => {
    const {children} = props;
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedBrkType, setSelectedBrkType] = useState();
    const [highlightTradeEntryFields, setHighlightTradeEntryFields] = useState({})
    const initPopUpDataState = [
        {'field':'tradeDate','header':'Trade Date','client':'','broker':'', isBreak:false},
        {'field':'settleDate','header':'Settle Date','client':'','broker':'', isBreak:false},
        {'field':'security','header':'Security','client':'','broker':'', isBreak:false},
        {'field':'symbol','header':'Symbol','client':'','broker':'', isBreak:false},
        {'field':'brokerCode','header':'Broker Code','client':'','broker':'', isBreak:false},
        {'field':'tranType','header':'Tran Type','client':'','broker':'', isBreak:false},
        {'field':'price','header':'Price','client':'','broker':'', isBreak:false},
        {'field':'quantity','header':'Quantity','client':'','broker':'', isBreak:false},
        {'field':'net','header':'Net','client':'','broker':'', isBreak:false},
        {'field':'interest','header':'Interest','client':'','broker':'', isBreak:false},
        {'field':'commission','header':'Commission','client':'','broker':'', isBreak:false},
        {'field':'execBroker','header':'Exec Brk','client':'','broker':'', isBreak:false},
        {'field':'dtcControlNumber','header':'DTC Control Number','client':'','broker':'', isBreak:false},
        {'field':'reason','header':'Reason','client':'','broker':'', isBreak:false},
    ]
    const [selectedBreak, setSelectedBreak] = useState({});
    const [popUpData, setPopUpData] = useState(JSON.parse(JSON.stringify(initPopUpDataState)));
    const [viewClicked, setViewClicked] = useState(false);

    const tradeBreakTypeHashmap = {
        "BRKS":"Client Only",
        "UNCN":"Broker Only",
        "MTPT":"Match with Differences",
        "MTCH":"Matches",
        "CACN":"Cancels"
    }
    const fieldToReasonColMapping = {
        'quantity': 'NetQuantity',
        'net': 'NetAmount',
        'execbroker': 'Executing Broker',
        'price':'Price',
        'settledate':'Settlement Date',
        'commission':'Commission',
        'interest':'Interest'
    }

    const getReasonEquivalentOfField =(field)=> {
        if(!!field)
            field = field.replace('broker','').replace('client','').toLowerCase()
        return fieldToReasonColMapping[field];
    }

    const mapTradeBreakType = (param) => {
        if(!!param)
            return tradeBreakTypeHashmap[param.data.breakType]
        return ''
    }

    const accountCodeMapping = (param) =>{
        if(!!param)
            return param.data.breakType === 'UNCN'? param.data.brokerAccountCode : param.data.clientAccountCode;
        return ''
    }

    const cellStyleChecker=(param)=>{
        let columnName = param.colDef.field;
        let reason = param.data.clientReason;
        if (checkColumnInReason(columnName,reason)) return {backgroundColor: '#F7E8E9', color:'#AE1100'}
        return
    }

    const numericCellStyleChecker=(param)=>{
        const numericStyle = {textAlign: 'right'};
        return {...numericStyle,...cellStyleChecker(param)}
    }

    const checkColumnInReason=(columnName,reason)=>{
        if(!!reason && !!columnName && reason.includes(getReasonEquivalentOfField(columnName)))
            return true;
        return false
    }

    const columnDefsTradeBreaks = [

        {headerName: '', marryChildren:true, children:[
                {headerName: 'id', field: 'id', hide:true, suppressColumnsToolPanel:true},
                {headerName: 'Break Type', field: 'breakType', valueGetter:mapTradeBreakType, pinned:'left', width:'115'},
                {headerName: 'Entity', field: 'shortName', pinned:'left', width:'90'},
                {headerName: 'Account', field: 'accountCode',valueGetter:accountCodeMapping, pinned:'left', width:'100'},
                {headerName: 'Security', field: 'security', pinned:'left', width:'100'},
            ]},


        {headerName:'Client', marryChildren:true, children:[

                {headerName: 'Trade Date', field: 'clientTradeDate', width:115},
                {headerName: 'Settle Date', field: 'clientSettleDate', width:115},
                {headerName: 'Tran Type', field: 'clientTranType', width:110},
                {headerName: 'Price', field: 'clientPrice',width:95, cellStyle:numericCellStyleChecker},
                {headerName: 'Quantity', field: 'clientQuantity', width:105, valueFormatter: numberFormatIntegerStr , cellStyle:numericCellStyleChecker},
                {headerName: 'Net', field: 'clientNet',width:95, valueFormatter: params => params.value ? numberFormat4FractionalDigits(params): '', cellStyle:numericCellStyleChecker},
                {headerName: 'Interest', field: 'clientInterest', width:100, cellStyle:numericCellStyleChecker},
                {headerName: 'Commission', field: 'clientCommission', width:125, cellStyle:numericCellStyleChecker},
                {headerName: 'Exec Brk', field: 'clientExecBroker', width:100},
            ]},
        {headerName:'Broker', marryChildren:true, children:[
                {headerName: 'Broker Code', field: 'brokerCode',width:125},
                {headerName: 'Trade Date', field: 'brokerTradeDate',width:115},
                {headerName: 'Settle Date', field: 'brokerSettleDate',width:115},
                {headerName: 'Tran Type', field: 'brokerTranType',width:110},
                {headerName: 'Price', field: 'brokerPrice',width:95},
                {headerName: 'Quantity', field: 'brokerQuantity',width:105, valueFormatter: numberFormatIntegerStr , cellStyle:numericCellStyleChecker},
                {headerName: 'Net', field: 'brokerNet', width:95, valueFormatter: params => params.value ? numberFormat4FractionalDigits(params): '', cellStyle:numericCellStyleChecker},
                {headerName: 'Interest', field: 'brokerInterest',width:100, cellStyle:numericCellStyleChecker},
                {headerName: 'Commission', field: 'brokerCommission',width:125,cellStyle:numericCellStyleChecker},
                {headerName: 'Exec Brk', field: 'brokerExecBroker',width:100},
                {headerName: 'DTC Control Number', field: 'brokerDtcControlNumber', width:170},
                {headerName: 'Reason', field: 'brokerReason', width:150},
            ]},

    ];
    const tradeBreakContext = {
        popUpData,
        setPopUpData,
        showInfoModal,
        setShowInfoModal,
        tradeBreakTypeHashmap,
        columnDefsTradeBreaks,
        selectedBrkType,
        setSelectedBrkType,
        selectedBreak,
        mapTradeBreakType,
        accountCodeMapping,
        cellStyleChecker,
        getReasonEquivalentOfField,
        setSelectedBreak,
        highlightTradeEntryFields,
        setHighlightTradeEntryFields,
        viewClicked, setViewClicked
    }

    return <Context.Provider value={tradeBreakContext}>{children}</Context.Provider>

};

export const {Consumer} = Context;
