import {createContext, useState} from "react";
import {
    formatDateString,
    getPrevBusDate,
    getPreviousDateToDatePickerFormat
} from "utils/FormatUtils"

export const QUERY_PANEL_HOME_PAGE = "query-home";
export const QUERY_PANEL_VIEW_QUERY_PAGE = "view-query";

const startDate =  getPrevBusDate() ===  '' ? getPreviousDateToDatePickerFormat() :
    formatDateString(String(getPrevBusDate()), 'YYYY-MM-DD');

export const Context = createContext({});

const initQueryFilterState = {
    queryName:'',
    queryId:'',
    startDate,
    endDate: '',
    isRangeSet: false
}

const initQueryResultsDisplay = {
    isSavedQuery:false,
    savedQueryId:'',
    savedName :'',
    querySearch:{},
    resData:[]
}

const initQueryPanelPageState = {
    currentPage : QUERY_PANEL_HOME_PAGE,
}

export const Provider = props => {


    const {children} = props;
    const [queryPanelFilterState, setQueryPanelFilterState] = useState(JSON.parse(JSON.stringify(initQueryFilterState)));
    const [queryResultsDisplayState, setQueryResultsDisplayState] = useState(JSON.parse(JSON.stringify(initQueryResultsDisplay)));
    const [queryPanelPageState, setQueryPanelPageState] = useState(JSON.parse(JSON.stringify(initQueryPanelPageState)));
    const [isHomeRequest, setIsHomeRequest] = useState(false)
    const [showGridToolbar, setShowGridToolbar] = useState(true);
    const [isDefaultEndDate, setIsDefaultEndDate] = useState(true);
    const [isAggEnabled, setIsAggEnabled] = useState(false)

    const clearQueryPanelDisplayState = () => {
        const clearQueryPanelFilterState = JSON.parse(JSON.stringify(initQueryFilterState));
        setQueryPanelFilterState(clearQueryPanelFilterState);

        const clearQueriesResultsDisplayState = JSON.parse(JSON.stringify(initQueryResultsDisplay));
        setQueryResultsDisplayState(clearQueriesResultsDisplayState);

        const clearQueryPanelPageState = JSON.parse(JSON.stringify(initQueryPanelPageState));
        setQueryPanelPageState(clearQueryPanelPageState);
    }

    const displayQueryPanelPage = (queryPanelPage) => {
        let queryPanelPageStateToShow=JSON.parse(JSON.stringify(queryPanelPageState));
        queryPanelPageStateToShow.currentPage = queryPanelPage;
        setQueryPanelPageState(queryPanelPageStateToShow);
    }


    const queriesPanelContext = {
        queryPanelFilterState: queryPanelFilterState,
        setQueryPanelFilterState: setQueryPanelFilterState,
        queryResultsDisplayState: queryResultsDisplayState,
        setQueryResultsDisplayState: setQueryResultsDisplayState,
        queryPanelPageState: queryPanelPageState,
        setQueryPanelPageState: setQueryPanelPageState,
        isHomeRequest,
        setIsHomeRequest,
        showGridToolbar,
        setShowGridToolbar,
        displayQueryPanelPage: displayQueryPanelPage,
        clearQueryPanelDisplayState: clearQueryPanelDisplayState,
        isDefaultEndDate,
        setIsDefaultEndDate,
        isAggEnabled, setIsAggEnabled
    }


    return <Context.Provider value={queriesPanelContext}>{children}</Context.Provider>

};

export const {Consumer} = Context;
