import React, {useContext, useEffect, useRef, useState} from 'react';
import {CustomSelect} from 'components/CustomSelect'

import {Col, Form} from "react-bootstrap";
import {EntityAccountsContext} from "./context";
import {useLocation} from "react-router";
import {showAsterisk} from "../../utils/FormatUtils";
import {getSessionAttribute, setSessionAttribute} from "../../utils/SessionUtils";
import {SELECTED_ACCOUNT, SELECTED_ENTITY, ACCOUNTS_OPTIONS, LEGAL_ENTITY} from "../../utils/Constants";
import AuthenticationService from "../../service/AuthenticationService";

const EntityAccountsComponent = (props) => {
    const {clearAccountDetails, setInitEntityAccountsOptions, entityAccountsOptions, accountsOptions, entityAccounts, setManagerDetails, setAccountDetails, getAccountsForSelectedEntity} = useContext(EntityAccountsContext);
    const [localEntityAccountsOptions,setLocalEntityAccountsOptions] = useState(JSON.parse(JSON.stringify(entityAccountsOptions)))
    const [options,setOptions] = useState([])
    const [selectedOption,setSelectedOption] = useState({})
    const {accountOptional, ssaEntityWidthFull, onAccountChange} = props
    const location  = useLocation();
    const pathname = location.pathname;



    const handleAccountChange = (e) => {

        const accountId = e.target.value;
        const accountToSelect = accountsOptions.find(account => account.accountId.toString() === accountId);

        if(accountToSelect) {
            setAccountDetails(accountToSelect.accountId, accountToSelect.name, accountToSelect.accountCode);
            setSessionAttribute(LEGAL_ENTITY,accountToSelect.legalEntity)
            setSessionAttribute(SELECTED_ACCOUNT, null);
            setSessionAttribute(SELECTED_ACCOUNT, accountToSelect);
            if(!AuthenticationService.isInternalUser()){ // Currently all external users have Wire Submission unless not configured in Client Master
                onAccountChange()
            }
        }
        else{
            let firstAccountForLegalEntity = getAccountsForSelectedEntity(getSessionAttribute(SELECTED_ENTITY).managerId)[0]
            setSessionAttribute(SELECTED_ACCOUNT, {accountId: '', name: '', accountCode: null});
            setSessionAttribute(LEGAL_ENTITY, firstAccountForLegalEntity?.legalEntity)
            setAccountDetails('','',null);
        }
    }
    useEffect(() => {
        getSessionAttribute(ACCOUNTS_OPTIONS) ?? setInitEntityAccountsOptions();
    }, []);

    useEffect(()=>{
        setLocalEntityAccountsOptions(JSON.parse(JSON.stringify(entityAccountsOptions)))
    },[entityAccountsOptions])

    useEffect(()=>{

        const options = []
        localEntityAccountsOptions.map(e=>{
            let option = {}
            option['value'] = e.managerId
            option['label'] = e.shortName+'-'+e.longName
            options.push(option)
        })
        setOptions(options)
    },[localEntityAccountsOptions])

    useEffect(() => {
        if (entityAccounts.disableAccounts)
            clearAccountDetails();
    }, [entityAccounts.disableAccounts]);

    useEffect(()=>{
        const selectedEntity = options.filter(e=>e.value===Number(entityAccounts.managerId))
        if(selectedEntity.length>0)
        {
            setSelectedOption(selectedEntity[0]);
        }
        else{
            setSelectedOption({})
        }

    },[entityAccounts.managerId,options])


    const handleChangeAutocomplete = (e)=>{
        setManagerDetails(e.value, e.label);

        let selectedEntity = {};
        selectedEntity['managerId']=e.value;
        selectedEntity['managerName']=e.label;
        setSessionAttribute(SELECTED_ENTITY,selectedEntity);
        let firstAccountForLegalEntity = getAccountsForSelectedEntity(selectedEntity['managerId'])[0]
        setSessionAttribute(LEGAL_ENTITY, firstAccountForLegalEntity?.legalEntity)
        setSessionAttribute(SELECTED_ACCOUNT, {accountId: '', name: '', accountCode: null});
    }
    const handleInputChangeAutoComplete = (enteredValue)=>{

        const newData = entityAccountsOptions.filter(e=> {
                return e.shortName.toLowerCase().includes(enteredValue.toLowerCase()) || e.longName.toLowerCase().includes(enteredValue.toLowerCase())
            }
        )
        setLocalEntityAccountsOptions(newData)

    }

    return(<>
        <Col md={'12'} sm={ssaEntityWidthFull?'12':'6'} >
            <Form.Group controlId="formGridEntity"   id={(!!entityAccounts.formErrors.search.entity? 'td-select-error':'')}>
                <Form.Label className="filter-input-label">Entity *</Form.Label>
                    <CustomSelect
                        isDisabled={props.isReadOnly ?? false}
                        placeholder={'Select'}
                        options={options}
                        value={Object.keys(selectedOption).length > 0?selectedOption:null}
                                   onChange={handleChangeAutocomplete}
                                   onInputChange={handleInputChangeAutoComplete}
                        isValid={!entityAccounts.formErrors.search.entity}
                        className={!entityAccounts.formErrors.search.entity?'is-invalid invalid':''}
                    />

                {!!entityAccounts.formErrors.search.entity &&
                    <b className={'error-text'}><i className="bi bi-exclamation-circle"></i>{entityAccounts.formErrors.search.entity}</b>
                }
            </Form.Group>
        </Col>
        {entityAccounts.showAccounts &&
            <Col md={'12'} sm={'6'}>
                <Form.Group controlId="formGridAccount" className={'td-select'} id={(!!entityAccounts.formErrors.search.account?!accountOptional?'td-select-error':'':'')}>
                    <Form.Label className="filter-input-label" >Account {showAsterisk(pathname) ? '*' : '' }</Form.Label>
                    <Form.Control as="select" name='accounts' value={entityAccounts.accountId} onChange={handleAccountChange} disabled={entityAccounts.disableAccounts}
                                  isInvalid={!!entityAccounts.formErrors.search.account && !accountOptional} >
                        <option value={''}>Select </option>
                        {accountsOptions &&
                            accountsOptions.map((account) => <option key={account.accountId} value={account.accountId}>{account.accountCode}-{account.name} </option>)}
                    </Form.Control>
                    <Form.Control.Feedback type={'invalid'} >
                        <b><i className="bi bi-exclamation-circle"></i>{entityAccounts.formErrors.search.account}</b>
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        }

    </>);

};

export {EntityAccountsComponent};

EntityAccountsComponent.defaultProps = {
    accountOptional: false,
    ssaEntityWidthFull: false,
    onAccountChange: () => {
        console.log("Do nothing if you are not on Asset Transfers-> Wires screen")
    }
}