import {createContext, useState} from "react";

export const Context = createContext({});
export const HOME_PAGE_TOP_POSITIONS_COUNT = 5;
export const HOME_PAGE_TOP_QUERY_COUNT = 5;
export const HOME_PAGE_TOP_REPORT_COUNT = 5;

export const NOTIFICATION_TYPE_ALERT = 'ALERT';
export const NOTIFICATION_TYPE_INFORMATION = 'INFORMATION';

const initPortalAlertState = {
    alerts: [],
    readIndex: -1
}

const initialEntityAccounts = {
    managerId: '',
    managerName: '',
    accountId: '',
    accountCode: null
};

export const Provider = props => {

    const {children} = props;
    const [portalAlertState, setPortalAlertState] = useState(JSON.parse(JSON.stringify(initPortalAlertState)));
    const [acctBalData,setAcctBalData] = useState([]);

    const [entityAccountsOptions, setEntityAccountsOptions] = useState([]);
    const [accountsOptions, setAccountsOptions] = useState([]);
    const [entityAccounts, setEntityAccounts] = useState(JSON.parse(JSON.stringify(initialEntityAccounts)));

    const setManagerDetails = (managerId, managerName) => {
        setManagerAndAccountDetails(managerId, managerName, '', '');
    };

    const setManagerAndAccountDetails = (managerId, managerName, accountId, accountCode) => {
        if (managerId === "") {
            clearHomeState();
            return;
        }

        const newEntityAccounts = JSON.parse(JSON.stringify(entityAccounts));
        newEntityAccounts.managerId = managerId;
        newEntityAccounts.managerName = managerName;
        newEntityAccounts.accountId = accountId;
        newEntityAccounts.accountCode = accountCode;
        setEntityAccounts(newEntityAccounts);
        loadAccountOptionsOnManagerChange(managerId);
    };

    const setAccountDetails = (accountId, accountCode) => {
        const newEntityAccounts = {...entityAccounts};
        newEntityAccounts.accountId = accountId;
        newEntityAccounts.accountCode = accountCode;
        setEntityAccounts(newEntityAccounts);
    };

    const loadAccountOptionsOnManagerChange = (managerId) => {
        let accountsForEntity = getAccountsForSelectedEntity(managerId);
        if (!!accountsForEntity && accountsForEntity.length>0) {
            const availableAccountsOptions = JSON.parse(JSON.stringify(accountsForEntity))
            if(!!availableAccountsOptions && availableAccountsOptions.length>0)
                setAccountsOptions(availableAccountsOptions);
            else
                setAccountsOptions([]);

            return;
        }
        setAccountsOptions([]);
    }

    const getAccountsForSelectedEntity = (selectedManagerId) => {
        let managerAccounts = entityAccountsOptions.find(({ managerId }) => managerId.toString() === selectedManagerId.toString());

        if (managerAccounts) {
            return managerAccounts.accounts;
        }
        return null;
    };

    const clearHomeState = () =>{
        let entityAccountsState = JSON.parse(JSON.stringify(initialEntityAccounts));
        setEntityAccounts(entityAccountsState);
        setAcctBalData([])
    }

    const homePageContext = {
        acctBalData: acctBalData,
        setAcctBalData: setAcctBalData,
        clearHomeState,
        portalAlertState,
        setPortalAlertState,
        entityAccountsOptions, setEntityAccountsOptions,
        accountsOptions, setAccountsOptions,
        entityAccounts, setEntityAccounts,
        setManagerDetails, setAccountDetails,
        getAccountsForSelectedEntity,
        setManagerAndAccountDetails,
    }
    return <Context.Provider value={homePageContext}>{children}</Context.Provider>
};

export const {Consumer} = Context;
