import React, {useContext, useEffect, useState} from 'react';
import {Row, Col, Container, Form} from "react-bootstrap";
import useWindowDimensions from "../../hooks/Dimensions";
import ActionModal from "../../components/ActionModal";
import {WiresPanelContext} from "./context";
import {SecurityContext} from "context/security";
import AuthenticationService from "service/AuthenticationService";
import {EntityAccountsContext} from "components/entity-accounts/context";
import * as URL from "../../service/UrlConstant";
import {WIRE_PANEL_HOME_PAGE, WIRE_PANEL_VIEW_WIRE_PAGE} from "./context/WiresPanelContext";
import {ToastContext} from "../../context/Toast";
import {
    dateToString,
    getCurrentDateToDatePickerFormat,
    getPreviousDateToDatePickerFormat
} from "../../utils/FormatUtils";
import {validateValueOnRegex} from "../../utils/RegexUtils";
import {
    REGEX_DECIMAL_MATCH,
    REGEX_WIRES_NEW_CLIENT_CODE,
    REGEX_WIRES_NEW_COMMENTS, REGEX_WIRES_NEW_INCOMING_CURRENCY_CODE, REGEX_WIRES_NEW_OUTGOING_CURRENCY_CODE,
    REGEX_WIRES_NEW_REASON,
    REGEX_WIRES_NEW_TEMPLATE_NAME,
    REGEX_WIRES_NEW_WIRE_INST, SELECTED_ACCOUNT, VALIDATION_IN_JS
} from "../../utils/Constants";
import {
    REGEX_WIRES_NEW_CLIENT_CODE_MSG,
    REGEX_WIRES_NEW_COMMENTS_MSG,
    REGEX_WIRES_NEW_INCOMING_CURRENCY_CODE_MSG,
    REGEX_WIRES_NEW_OUTGOING_CURRENCY_CODE_MSG,
    REGEX_WIRES_NEW_REASON_MSG,
    REGEX_WIRES_NEW_TEMPLATE_NAME_MSG,
    REGEX_WIRES_NEW_WIRE_INST_MSG,
    WIRES_NEW_INCOMING_OUTGOING_VALUE_ZERO_ERROR
} from "../../utils/ConstantsMsg";
import {getAllErrorValuesAsString} from "../../utils/ErrorUtils";
import {CustomSelect} from "../../components/CustomSelect";
import {setSessionAttribute} from "../../utils/SessionUtils";

export default function AddNewWire(props) {
    const {isDesktop, isTab,isMobile} = useWindowDimensions();
    const bodyStyle=isDesktop?{paddingTop:0}:{};

    const {entityAccountsOptions, accountsOptions, entityAccounts, setManagerDetails, setAccountDetails,validateEntityAccountSearch} = useContext(EntityAccountsContext);
    const {makePostRequest,makeGetRequest} = useContext(SecurityContext);
    const {wireNewState, setWireNewState,clearWireNewState,displayWirePanelPage,wirePanelPageState,wireResultsDisplayState,setWireResultsDisplayState, wireTemplatesList, setWireTemplatesList, wireCurrenciesList, setWireCurrenciesList, isWireSubmissionAllowedForAccount,
        disableRequestWires} = useContext(WiresPanelContext);
    const [formErrors, setFormErrors] = useState({search: {}});
    const {success,error} = useContext(ToastContext);
    const [options, setOptions] = useState([]);
    const [localEntityAccountsOptions,setLocalEntityAccountsOptions] = useState(JSON.parse(JSON.stringify(entityAccountsOptions)))
    const [selectedOption,setSelectedOption] = useState({})
    let userName = AuthenticationService.getAuthenticatedUserName();

    const getCurrencies = () => {

        if (wireNewState.managerName == null  || wireNewState.managerName == "") {
            return;
        }

        const params = {
            manager:wireNewState.managerName
        };
        const onSuccess = (response) => {
            setWireCurrenciesList(response.data)
        }
        const onError = (err) => {
            console.error(err);
        }
        makeGetRequest(URL.WIRES_CURRENCY_URL, params,onSuccess, onError);
    }

    const getWireTemplates = () => {
        if (wireNewState.clientCode == null || wireNewState.clientCode === "") {
            return;
        }

        const params = {
            lglEntity:"ALL",
            cdAcct: wireNewState.clientCode,
            dtsStart:"",
            dtsEnd :"",
            indTemplate:'Y'
        };
        const onSuccess = (response) => {
            setWireTemplatesList(response.data)
        }
        const onError = (err) => {
            console.error(err);
        }
        makePostRequest(params,URL.WIRES_TEMPLATES_URL, onSuccess, onError);
    }

    const handleChange = (e,name) => {
        if(name === 'thirdPartyCode')
        {
            if(e.currentTarget.id ==='3rd-radio-yes' && e.currentTarget.checked === true)
                setWireNewState({...wireNewState, ['thirdPartyCode']:"Y"});
            else if(e.currentTarget.id ==='3rd-radio-no' && e.currentTarget.checked === true)
                setWireNewState({...wireNewState, ['thirdPartyCode']:"N"});
        }
        else {
            setWireNewState({...wireNewState, [e.currentTarget.name]:e.currentTarget.value});
            formErrors.search[name] = '';
        }
    }

    const handleTemplateChange = (e) =>{
        if(e.currentTarget !== null) {
            const selectedIndex = e.currentTarget.selectedIndex;
            const templateName = e.currentTarget[selectedIndex].text;
            const keySelected = e.currentTarget[selectedIndex].value;

            const wireNewStateUpdated = JSON.parse(JSON.stringify(wireNewState));
            if (keySelected == "-1") {
                wireNewStateUpdated[e.currentTarget.name] = "";
                wireNewStateUpdated.instructions = "";
            }
            else {
                const instructions = getTemplateInstruction(keySelected);
                wireNewStateUpdated.instructions = instructions;
                wireNewStateUpdated[e.currentTarget.name] = templateName;
                wireNewStateUpdated["indTemplate"] = "N";
                formErrors.search.instructions = '';
                formErrors.search.templateName = '';
            }

            setWireNewState(wireNewStateUpdated);
        }
    }

    function getTemplateInstruction(templateName) {
        if (wireTemplatesList == null) {
            return "";
        }

        for (let i=0; i<wireTemplatesList.length; i++) {
            if (wireTemplatesList[i].templateName == templateName) {
                return wireTemplatesList[i].instruction;
            }
        }

        return "";
    }

    const handleManagerChange = (e,name) => {
        const managerId = e.currentTarget.value;
        const managerToSelect = entityAccountsOptions.find(manager => manager.managerId.toString() === managerId);
        if(managerToSelect) {
            setManagerDetails(e.target.value, e.currentTarget.options[e.currentTarget.selectedIndex].innerHTML);
            formErrors.search.entity = '';

            setWireNewState(
                {
                    ...wireNewState,
                    ['managerId']: managerId,
                    ['managerName']: managerToSelect.shortName
                });
        } else {
            setManagerDetails('', '');
            formErrors.search.entity = '';

            setWireNewState({
                ...wireNewState,
               managerId: '',
                managerName: '',
            });
        }
    }

    const handleChangeAutocomplete = (e)=>{
        formErrors.search.entity = ''
        setManagerDetails(e.value, e.label);

        setWireNewState(
            {
                ...wireNewState,
                ['managerId']: e.value,
                ['managerName']: e.label
            });
    }
    const handleInputChangeAutoComplete = (enteredValue)=>{
        formErrors.search.entity = ''

        const newData = entityAccountsOptions.filter(e=> {
                return e.shortName.toLowerCase().includes(enteredValue.toLowerCase()) || e.longName.toLowerCase().includes(enteredValue.toLowerCase())
            }
        )

        setLocalEntityAccountsOptions(newData);
    }

    const handleAccountChange = (e,name) => {

        const accountId = e.target.value;
        const accountToSelect = accountsOptions.find(account => account.accountId.toString() === accountId);

        if(accountToSelect) {
            setAccountDetails(accountToSelect.accountId, accountToSelect.name, accountToSelect.accountCode);
            setSessionAttribute(SELECTED_ACCOUNT, accountToSelect);
            formErrors.search.account = '';
            setStateBasedOnAccount(accountToSelect.accountId,accountToSelect.accountCode, accountToSelect.name)
            isWireSubmissionAllowedForAccount()
        }
        else{
            setAccountDetails('','',null);
            setStateBasedOnAccount(null,null,null)
            setWireNewState(prevState => ({
                ...prevState,
                incomingCcy: '',
                outgoingCcy: '',
            }));
        }

}

    const validateAddWireRequired = ()=> {
        let errors = {}
        if (!wireNewState.managerId || wireNewState.managerId === '') {
            errors['entity'] = 'Please select entity'
        }

        if (!wireNewState.accountId || wireNewState.accountId === '') {
            errors['account'] = 'Please select account'
        }

        if (!wireNewState.reason) {
            errors['reason'] = 'Please enter reason'
        }

        if (!wireNewState.templateName || wireNewState.templateName == "") {
            errors['templateName'] = 'Please select a template'
        }

        if (!wireNewState.instructions) {
            errors['instructions'] = 'Please enter instructions'
        }
        return errors
    }
    const validateAddWireRegex = ()  => {
        let errors = {}
        if (wireNewState.comments){
            let match = validateValueOnRegex(wireNewState.comments,REGEX_WIRES_NEW_COMMENTS)
            if (!match){
                errors['comments'] = REGEX_WIRES_NEW_COMMENTS_MSG
            }
        }
        if (wireNewState.reason){
            let match = validateValueOnRegex(wireNewState.reason,REGEX_WIRES_NEW_REASON)
            if (!match){
                errors['reason'] = REGEX_WIRES_NEW_REASON_MSG
            }
        }
        if (wireNewState.templateName){
            let match = validateValueOnRegex(getTemplateNameWithoutAccountData(wireNewState.templateName),REGEX_WIRES_NEW_TEMPLATE_NAME)
            if (!match){
                errors['templateName'] = REGEX_WIRES_NEW_TEMPLATE_NAME_MSG
            }
        }
        if (wireNewState.instructions){
            let match = validateValueOnRegex(wireNewState.instructions,REGEX_WIRES_NEW_WIRE_INST)
            if (!match){
                errors['instructions'] = REGEX_WIRES_NEW_WIRE_INST_MSG
            }
        }
        if (wireNewState.incomingCcy){
            let match = validateValueOnRegex(wireNewState.incomingCcy,REGEX_WIRES_NEW_INCOMING_CURRENCY_CODE)
            if (!match){
                errors['incomingCcy'] = REGEX_WIRES_NEW_INCOMING_CURRENCY_CODE_MSG
            }
        }
        if (wireNewState.outgoingCcy){
            let match = validateValueOnRegex(wireNewState.outgoingCcy,REGEX_WIRES_NEW_OUTGOING_CURRENCY_CODE)
            if (!match){
                errors['outgoingCcy'] = REGEX_WIRES_NEW_OUTGOING_CURRENCY_CODE_MSG
            }
        }
        if (wireNewState.clientCode){
            let match = validateValueOnRegex(wireNewState.clientCode,REGEX_WIRES_NEW_CLIENT_CODE)
            if (!match){
                errors['account'] = REGEX_WIRES_NEW_CLIENT_CODE_MSG
            }
        }
        return errors
    }

    // check incoming or outgoing value and currencies relations
    // incoming or outgoing value must be > 0 and only one
    // if incoming value is valid, need incoming currency is selected
    // if outgoing value is valid, need outgoing currency is selected
    const validateAddWireMoneyInOut = () => {
        let errors = {}
        let moneyInFloat = wireNewState.moneyIn?parseFloat(wireNewState.moneyIn):0.00
        let moneyOutFloat = wireNewState.moneyOut?parseFloat(wireNewState.moneyOut):0.00

        let moneyInExist = moneyInFloat > 0.00
        let moneyInZero = (Math.abs(moneyInFloat) < 0.000001)
        let moneyInNegative = moneyInFloat < 0.00

        let moneyOutExist = moneyOutFloat > 0.00
        let moneyOutZero = (Math.abs(moneyOutFloat) < 0.000001)
        let moneyOutNegative = moneyOutFloat < 0.00
        let msg = ""
         if (moneyInZero && moneyOutZero){
             msg = WIRES_NEW_INCOMING_OUTGOING_VALUE_ZERO_ERROR
             errors["moneyIn"] = msg
             errors["moneyOut"] = msg
         } else if (moneyInZero === false && moneyOutZero === false) {
             msg = WIRES_NEW_INCOMING_OUTGOING_VALUE_ZERO_ERROR
            errors["moneyIn"] = msg
            errors["moneyOut"] = msg
        } else {
             // incoming or outgoing value, one not zero, another zero
             if (!moneyInZero){
                 // check moneyInCurrency
                 if (!wireNewState.incomingCcy){
                     errors["incomingCcy"] = "Please select Incoming Currency"
                 }
             }
             if (!moneyOutZero){
                 // check moneyOutCurrency
                 if (!wireNewState.outgoingCcy){
                     errors["outgoingCcy"] = "Please select Outgoing Currency"
                 }
             }
        }

         // check negative
        if (moneyInNegative){
            errors["moneyIn"] = "Incoming value should be greater than 0"
        }
        if (moneyOutNegative){
            errors["moneyOut"] = "Outgoing value should be greater than 0"
        }

        return errors
    }

    const validateAddWire = ()=> {
        let errors = validateAddWireRequired()

        // regex validation
        let errorsRegex = validateAddWireRegex()
        errors = {...errors, ...errorsRegex}
        // check incoming or outgoing value and currencies relations
        let errorsMoneyInOut = validateAddWireMoneyInOut()
        errors = {...errors, ...errorsMoneyInOut}

        return errors
    }

    const handleViewWires = () =>{
        const params = {
            legalEntity: entityAccounts.managerName,
            cdAcct:entityAccounts.accountCode,
            clientCode: entityAccounts.accountCode,
            startDate: dateToString(getPreviousDateToDatePickerFormat()),
            endDate:dateToString(getCurrentDateToDatePickerFormat()),
            status:"ALL",
            templateIndicator:"N",
            deskViewIndicator:"N",
            exportIndicator:0
        }

        const onSuccess = (res) => {
            const wireResultsDisplay = JSON.parse(JSON.stringify(wireResultsDisplayState));
            wireResultsDisplay.resData = ([...res.data]);
            wireResultsDisplay.isSavedWire = false;
            wireResultsDisplay.savedName = "";
            wireResultsDisplay.savedWireListId = "";
            wireResultsDisplay.wireSearch = {
                userCode:'',
                managerId: entityAccounts.managerId,
                managerName : entityAccounts.managerName,
                accountId: entityAccounts.accountId,
                accountName : entityAccounts.accountName,
                accountCode : entityAccounts.accountCode,
                subManagerId : entityAccounts.subManagerId,
                subManagerName : entityAccounts.subManagerNameShort,
                legalEntity:'',
                clientCode: entityAccounts.accountCode,
                status: "ALL",
                startDate:dateToString(getPreviousDateToDatePickerFormat()),
                endDate:dateToString(getCurrentDateToDatePickerFormat()),
                badgeInfo: !!entityAccounts.accountCode?entityAccounts.accountCode+'-'+entityAccounts.accountName:entityAccounts.accountName
            };
            setWireResultsDisplayState(wireResultsDisplay);
            props.onHide();
            clearWireNewState();
            displayWirePanelPage(WIRE_PANEL_VIEW_WIRE_PAGE);
        }
        const onError = (err) => {
            console.error('Error when retrieving wires - ', err);
            props.onHide();
            clearWireNewState();
           // error("There was an error when loading wires !!");
        }
        makePostRequest(params, URL.WIRES_WIRE_SEARCH, onSuccess, onError);
    }

    const handleCancelWires = (e) => {
        clearWireNewState();
        props.onHide();
    }
    const handleAddWires = (e) => {
        e.preventDefault();

        let errors = {}
        if (VALIDATION_IN_JS){
            errors = validateAddWire()
        }
        const newFormErrors = {...formErrors}
        newFormErrors['search'] = errors;
        setFormErrors(newFormErrors)

        if (Object.keys(errors).length !== 0) {
            return
        }

        const params = {
            clientId:0,
            clientCode:wireNewState.clientCode,
            thirdPartyCode: wireNewState.thirdPartyCode,
            moneyOut: parseFloat(wireNewState.moneyOut !="" ?wireNewState.moneyOut:0.00),
            outgoingCurrencyCode:wireNewState.outgoingCcy,
            reasonCode:wireNewState.reason,
            moneyIn: parseFloat(wireNewState.moneyIn !="" ?wireNewState.moneyIn:0.00),
            incomingCurrencyCode: wireNewState.incomingCcy,
            instruction: wireNewState.instructions,
            templateIndicator: wireNewState.indTemplate,
            templateName: getTemplateNameWithoutAccountData(wireNewState.templateName),
            comments: wireNewState.comments,
            status:"PEND"
        }

        const onSuccess = (res) => {
            success("Wire Created Successfully !!")
            if(WIRE_PANEL_VIEW_WIRE_PAGE === wirePanelPageState.currentPage) {
                    handleViewWires();
            }
            else {
                displayWirePanelPage(WIRE_PANEL_HOME_PAGE)
                props.onHide();
                clearWireNewState();
            }
        }
        const onError = (err) => {
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error('Error when adding new wire - ', errorMsg);
            error("Error when adding new wire")
        }

        makePostRequest(params,URL.WIRES_SAVE_URL, onSuccess, onError);

    }

    function getTemplateNameWithoutAccountData(templateName) {
        if (templateName === null) {
            return templateName;
        }
        let index = templateName.indexOf("- ");
        if (index > 0) {
            return templateName.slice(index + 2);
        }
    }

    useEffect( () =>{
        if(entityAccounts.accountId) {
            getWireTemplates();
        }
    },[entityAccounts])

    useEffect( () =>{
        if(!!wireNewState.accountId) {
            getWireTemplates();
        }
    },[wireNewState.accountId])

    useEffect(() => {
        getCurrencies();
    },[wireNewState.managerName])

    useEffect(()=>{
        setLocalEntityAccountsOptions(JSON.parse(JSON.stringify(entityAccountsOptions)))
    },[entityAccountsOptions])

    useEffect(()=>{

        const options = []
        entityAccountsOptions.map(e=>{
            let option = {}
            option['value'] = e.managerId
            option['label'] = e.shortName+'-'+e.longName
            options.push(option)
        })
        setOptions(options)
    },[localEntityAccountsOptions])

    useEffect(()=>{
        const selectedEntity = options.filter(e=>e.value===Number(wireNewState.managerId))
        if(selectedEntity.length>0)
        {
            setSelectedOption(selectedEntity[0]);
        }
        else{
            setSelectedOption({})
        }

    },[options, wireNewState])

    const modalBody = () => (
        <Container fluid>
            <Row style={{paddingBottom:0}}>
                <Col style={{paddingBottom:0}}>
                    <Container fluid>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formGridState" id={(!!formErrors.search.entity? 'td-select-error':'')}>
                                    <Form.Label className="filter-input-label">* Entity</Form.Label>

                                    <CustomSelect
                                        isDisabled={false}
                                        placeholder={'Select'}
                                        options={options}
                                        value={Object.keys(selectedOption).length > 0?selectedOption:null}
                                        onChange={handleChangeAutocomplete}
                                        onInputChange={handleInputChangeAutoComplete}
                                        isValid={!formErrors.search.entity}
                                        className={!entityAccounts.formErrors.search.entity?'is-invalid invalid':''}
                                        addNewWireModal={true}
                                       />


                                    {!!entityAccounts.formErrors.search.entity &&
                                    <Form.Control.Feedback type={'invalid'}>
                                        <b><i className="bi bi-exclamation-circle"></i>{formErrors.search.entity}</b>
                                    </Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Col>
                            {entityAccounts.showAccounts &&
                            <Col md={6}>
                                <Form.Group controlId="formGridState" className={'td-select'} id={(!!formErrors.search.account? 'td-select-error':'')}>
                                    <Form.Label className="filter-input-label">* Account</Form.Label>
                                    <Form.Control as="select" name='accounts' value={entityAccounts.accountId || ''} onChange={event => handleAccountChange(event, 'accounts')}
                                                  isInvalid={!!formErrors.search.account}>
                                        <option value={''}>Select</option>
                                        {accountsOptions &&
                                        accountsOptions.map((account) => <option key={account.accountId} value={account.accountId}>{account.accountCode}-{account.name}</option>)}
                                    </Form.Control>
                                    <Form.Control.Feedback type={'invalid'}>
                                        <b><i className="bi bi-exclamation-circle"></i>{formErrors.search.account}</b>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            }
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row  style={{paddingTop:0}}>
                <Col style={{paddingTop:0}}>
                    <Container fluid>
                        <Row>
                            <Col style={{alignSelf:"center"}}>
                                <b style={{whiteSpace:"nowrap"}}>* 3rd party</b>
                            </Col>
                            <Col style={{alignSelf:"center", paddingRight:0, paddingLeft:0}}>
                                <Form.Check
                                    name='thirdPartyCode'
                                    type='radio'
                                    id={'3rd-radio-yes'}
                                    label={"Yes"}
                                    onChange={event => handleChange(event, 'thirdPartyCode')}
                                    checked={wireNewState.thirdPartyCode==='Y'}
                                />
                            </Col>
                            <Col style={{alignSelf:"center", paddingRight:0, paddingLeft:0}}>
                                <Form.Check
                                    name='thirdPartyCode'
                                    type='radio'
                                    id={'3rd-radio-no'}
                                    label={"No"}
                                    onChange={event => handleChange(event, 'thirdPartyCode')}
                                    checked={wireNewState.thirdPartyCode==='N'}
                                /></Col>
                            <Col xs={"auto"}></Col>
                        </Row>
                    </Container>
                </Col>
                <Col className="d-none d-sm-block"></Col>
            </Row>

            <Row>
                <Col sm={12} md={6}>
                    <Container>
                        <Row>
                            <Col style={{textAlign:isDesktop?"center":"left", padding:20}} >
                                <h3>OUTGOING</h3>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6} md={12}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label className="filter-input-label">Outgoing Value</Form.Label>
                                    <Form.Control placeholder="0.00" name='moneyOut' value={wireNewState.moneyOut} pattern={REGEX_DECIMAL_MATCH} onChange={event => handleChange(event, 'moneyOut')}
                                          isInvalid={!!formErrors.search.moneyOut} />
                                    <Form.Control.Feedback type={'invalid'}>
                                        <b><i className="bi bi-exclamation-circle"></i>{formErrors.search.moneyOut}</b>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col sm={6} md={12}>
                                <Form.Group controlId="formGridState" >
                                    <Form.Label className="filter-input-label">* Outgoing Currency</Form.Label>
                                    <Form.Control as="select" name ='outgoingCcy' value={wireNewState.outgoingCcy} onChange={event => handleChange(event, 'outgoingCcy')}
                                        isInvalid={!!formErrors.search.outgoingCcy}>
                                        <option value="">Select</option>
                                        {wireCurrenciesList &&
                                        wireCurrenciesList.map((ccy) => <option key={ccy.currencyCode} value={ccy.currencyCode}>{ccy.currencyCode} - {ccy.currencyName} </option>)
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type={'invalid'}>
                                        <b><i className="bi bi-exclamation-circle"></i>{formErrors.search.outgoingCcy}</b>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col sm={6} md={12}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label className="filter-input-label">* Reason</Form.Label>
                                    <Form.Control name ='reason' value={wireNewState.reason} onChange={event => handleChange(event, 'reason')}
                                                  isInvalid={!!formErrors.search.reason}/>
                                    <Form.Control.Feedback type={'invalid'}>
                                        <b><i className="bi bi-exclamation-circle"></i>{formErrors.search.reason}</b>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col sm={6} md={12}>
                                <Form.Group controlId="formGridState" className={'td-select'} id={(!!formErrors.search.templateName? 'td-select-error':'')}>
                                    <Form.Label className="filter-input-label">* Existing Template(s)</Form.Label>
                                    <Form.Control as="select" name ="templateName" value={wireNewState.templateName}
                                                  onChange={event => handleTemplateChange(event)}
                                                  isInvalid={!!formErrors.search.templateName}>
                                        <option value={'-1'}>Select</option>
                                        {wireTemplatesList &&
                                        wireTemplatesList.map((template) => <option key={template.wireId} value={template.templateName}>{template.templateName}</option>)
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type={'invalid'}>
                                        <b><i className="bi bi-exclamation-circle"></i>{formErrors.search.templateName}</b>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label className="filter-input-label">* Wire Instructions</Form.Label>
                                    <Form.Control disabled={true} as="textarea" rows={4} name='instructions' value={wireNewState.instructions}
                                                  onChange={event => handleChange(event, 'instructions')}
                                                  isInvalid={!!formErrors.search.instructions}/>
                                    <Form.Control.Feedback type={'invalid'}>
                                        <b><i className="bi bi-exclamation-circle"></i>{formErrors.search.instructions}</b>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label className="filter-input-label" >Comment</Form.Label>
                                    <Form.Control name='comments' value={wireNewState.comments} onChange={event => handleChange(event, 'comments')}
                                       isInvalid={!!formErrors.search.comments} />
                                    <Form.Control.Feedback type={'invalid'}>
                                        <b><i className="bi bi-exclamation-circle"></i>{formErrors.search.comments}</b>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Col>

                <Col className={"td-vertical-divider-line-1 d-none d-md-block"} md={"auto"} style={{padding:0}}></Col>

                <Col sm={12} className="d-md-none d-sm-block pt-4">
                    <Container>
                        <Row>
                            <Col>
                                <hr className={"td-thin-divider-line-1"}/>
                            </Col>
                        </Row>
                    </Container>
                </Col>

                <Col style={{paddingTop:0}}>
                    <Container>
                        <Row>
                            <Col style={{textAlign: isDesktop?"center":"left", padding:20}}>
                                <h3>INCOMING</h3>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6} md={12}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label className="filter-input-label">Incoming Value</Form.Label>
                                    <Form.Control placeholder="0.00" name='moneyIn' value={wireNewState.moneyIn} pattern={REGEX_DECIMAL_MATCH} onChange={event => handleChange(event, 'moneyIn')}
                                        isInvalid={!!formErrors.search.moneyIn}/>
                                    <Form.Control.Feedback type={'invalid'}>
                                        <b><i className="bi bi-exclamation-circle"></i>{formErrors.search.moneyIn}</b>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col sm={6} md={12}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label className="filter-input-label">* Incoming Currency</Form.Label>
                                    <Form.Control as="select" name ='incomingCcy' value={wireNewState.incomingCcy} onChange={event => handleChange(event, 'incomingCcy')}
                                       isInvalid={!!formErrors.search.incomingCcy}>
                                        <option value="">Select</option>
                                        {wireCurrenciesList &&
                                        wireCurrenciesList.map((ccy) => <option key={ccy.currencyCode} value={ccy.currencyCode}>{ccy.currencyCode} - {ccy.currencyName}</option>)
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type={'invalid'}>
                                        <b><i className="bi bi-exclamation-circle"></i>{formErrors.search.incomingCcy}</b>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                    </Container>
                </Col>
            </Row>

            <Row>
                <Col></Col>
                <Col sm={12} md={6}>
                    <Container>
                        <Row>
                            <Col>
                                <button className="btn btn-block td-btn-secondary-clear" href="#" onClick={handleCancelWires}>Cancel</button>
                            </Col>
                            <Col>
                                <button className="btn btn-block td-btn-primary-light" href="#" disabled={disableRequestWires} onClick={handleAddWires}>Submit</button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )

    const modalTitle = () => (
        <Container>
            <Row>
                <Col>Create New Wire</Col>
            </Row>
        </Container>
    )


function setStateBasedOnAccount(accountId, clientCode, accountName){
    setWireNewState(
        {...wireNewState,
            'accountId':accountId,
            'clientCode':clientCode,
            'accountName':accountName
        }
    );
}

    return (<>
        <ActionModal body={modalBody} title={modalTitle} show={props.show} onHide={props.onHide} dialogClassName={isMobile? "mobile-fullscreen-dialog": (isTab ? "fullscreen-dialog" : "")} size={isDesktop?"lg":""} bodyStyle={bodyStyle} centered={true}></ActionModal>
    </>);
}
