import React, {useState} from "react";
import {Col, Form} from "react-bootstrap";

const Component0330 = (props) =>{
    const {baseUrl} = props;

    return(
        <Col className={`${props.isLogin?'text-left px-2':'text-center'} login-app-footer`}>
            <br/>
            {!props.isLogin &&
                (
                    <>
                        Client Portal Users: {props.isLogin && <br/>} Please
                        <a className="td-cta td-link" href={baseUrl+"/Content/pdf/TDPS_Client_Portal_Disclaimers_and_Disclosures_0330.pdf"} target="_blank"> click here to view important legal, disclaimer and disclosure information</a> for our client portal.<br/>
                        <a className="td-cta td-link" href={baseUrl+"/Content/pdf/TDPS_Financial_Statements_0330.pdf"} target="_blank">Financial Statements</a><br/>
                    </>
                )
            }
        </Col>
    )

}
export default Component0330