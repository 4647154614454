import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import ContactUsIcon from "components/ContactUsIcon";
import AuthenticationService from "../service/AuthenticationService";
import UserRoleService, {LEGAL_ENTITY_PERMISSION_KEY} from "../service/UserRoleService";
import {getSessionAttribute} from "../utils/SessionUtils";
import {LEGAL_ENTITY} from "../utils/Constants";
import Component0330 from "./Component0330";
import Component0108 from "./Component0108";
import {HomepageContext} from "../pages/home/context";
import {EntityAccountsContext} from "../components/entity-accounts/context";

export default function AppFooter(props) {
    const baseurl = `${process.env.PUBLIC_URL}`
    const [isLegalEntity, setIsLegalEntity] = useState(false);
    const {entityAccounts: entityAccountsHome} = useContext(HomepageContext);
    const {entityAccounts: entityAccountsOther} = useContext(EntityAccountsContext);

    const checkPermission = (menuKey) => {
        return UserRoleService.hasPermission(menuKey);
    }

    useEffect(() => {
        let tempLegalEntity = getSessionAttribute(LEGAL_ENTITY)
        setIsLegalEntity(tempLegalEntity === "0330"?true:false)
    },[entityAccountsHome, entityAccountsOther])

    return(<>
        <hr/>
        <footer className="td-bg-colour-7 pb-4">
            <Container fluid className="px-6">
                <Row>
                    {isLegalEntity? <Component0330 isLogin={props.isLogin} baseUrl={baseurl}/>:<Component0108 isLogin={props.isLogin} baseUrl={baseurl}/>}
                </Row>
            </Container>
            {!props.isLogin && !AuthenticationService.isInternalUser() && <ContactUsIcon/>}
        </footer>
    </>);
}
